<template>
  <router-link :to="to" class="nav-button" v-if="to">
    <figure class="icon"><iconic :name="icon" /></figure>
    <label class="label">{{ label }}</label>
  </router-link>
  <a class="nav-button" v-else @click="$emit('click')">
    <figure class="icon"><iconic :name="icon" /></figure>
    <label class="label">{{ label }}</label>
  </a>
</template>

<script>
export default {
  props: ["to", "icon", "label"],
};
</script>

<style lang="scss">
.nav-button {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  .icon {
    font-size: 28px;
    width: $mpadding * 2;
    margin: 0 $mpadding/2 0 0;
  }
  .label {
    font-weight: bold;
  }
  * {
    pointer-events: none;
  }
}
</style>
