<template>
  <div class="become-creator-btn">
    <router-link to="/user/creator" :style="$style(style)" dactive>
      <iconic :style="$style(icon)" name="comet" />
      <span>{{ $locale["words"]["become_creator"] }}</span>
    </router-link>
  </div>
</template>

<script>
export default {
  computed: {
    style: function() {
      return {
        cursor: "pointer",
        display: "flex",
        color: "#fff",
        padding: "0.6rem 1rem",
        "user-select": "none",
        "background-image": "linear-gradient(to right top, #bd0909, #cd2321, #dd3536, #ec464b, #fb575f)",
        "text-align": "center",
        "align-items": "center",
        "justify-content": "center",
        "border-radius": Go.math(this.$mpadding, "/", 2),
      };
    },
    icon: function() {
      return {
        margin: "0 calc(1rem / 2) 0 0",
      };
    },
  },
};
</script>
