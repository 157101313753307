<template>
  <div class="language-select">
    <div class="nav-button current" @click="toggleMenu">
      <div class="nav-button-left">
        <figure class="icon">
          <span class="flag" name="icon" :style="`--langFlag: url(${current === 'es' ? flags.es : flags.en})`"></span>
        </figure>
        <label class="label">{{ `${current === "es" ? "Español" : "English"}` }}</label>
      </div>
      <div class="nav-button-right"><iconic name="mf_chevron_down" /></div>
    </div>
    <div class="nav-button" :current="`${current === 'es' ? 'true' : 'false'}`" @click="setLanguage('es')">
      <div class="nav-button-left">
        <figure class="icon">
          <span class="flag" name="icon" :style="`--langFlag: url(${flags.es})`"></span>
        </figure>
        <label class="label">Español</label>
      </div>
    </div>
    <div class="nav-button" :current="`${current === 'en' ? 'true' : 'false'}`" @click="setLanguage('en')">
      <div class="nav-button-left">
        <figure class="icon">
          <span class="flag" name="icon" :style="`--langFlag: url(${flags.en})`"></span>
        </figure>
        <label class="label">English</label>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonNav from "../buttons/ButtonNav.vue";
export default {
  components: { ButtonNav },
  data: function() {
    return {
      current: localStorage.getItem(process.env.VUE_APP_LANG_KEY) || process.env.VUE_APP_LANG_DEFAULT,
      flags: {
        es: "https://onegroup.s3.amazonaws.com/website/spanish_flag.svg",
        en: "https://onegroup.s3.amazonaws.com/website/english_flag.svg",
      },
    };
  },
  methods: {
    toggleMenu: function() {
      const menu = document.querySelector(`.language-select`);
      if (menu) {
        menu.classList.toggle("opened");
      }
    },
    setLanguage: async function($lang) {
      localStorage.setItem(process.env.VUE_APP_LANG_KEY, $lang);
      this.current = $lang;
      this.isLoading(true);
      setTimeout(async () => {
        await this.$store.dispatch("startApp");
        this.$root.$emit("UpdateApp");
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.language-select {
  .nav-button {
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .flag {
      display: inline-block;
      width: 22px;
      height: 22px;
      background-color: #858585;
      border-radius: 50%;
      background-image: var(--langFlag);
      background-size: 160%;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0 0 0 $mpadding/4;
    }
    &-right {
      .iconic {
        @include Flex(inherit, center, center);
        font-size: 26px;
      }
    }
  }
  .nav-button:not(:first-child) {
    display: none;
  }
  &.opened {
    .nav-button {
      &-right {
        .iconic {
          transform: rotate(180deg);
        }
      }
      &:not(:first-child):not([current="true"]) {
        display: flex;
      }
    }
  }
}
</style>
