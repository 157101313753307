<template>
  <div class="user-menu" v-if="$user && $locale">
    <div class="user-menu-bgwrap" @click="toggleMenu"></div>
    <div class="user-menu-content">
      <div class="user-menu-card">
        <HamburgerBtn @action="toggleMenu" :action="true" onEscape />
        <div class="user-menu-card-head --section">
          <div class="user-menu-card-head-content flex-middle">
            <div class="user-menu-card-avatar">
              <UserAvatar :user="$user" type="" size="50px" @action="close" />
            </div>
            <div class="user-menu-card-info">
              <div class="name" bold>
                <span ellipsis>{{ $user.name }}</span>
              </div>
              <div class="userName">
                <small>{{ $user.email }}</small>
              </div>
            </div>
          </div>
          <CreatorStatsMenuWidget v-if="$isCreator" />
        </div>
        <div class="user-menu-card-nav --section">
          <ButtonNav icon="mf_lock" :label="$locale['manage']" @click="close(() => UserAdminMenu())" class="__m1a" />
          <ButtonNav
            icon="mf_user_solid"
            :label="$locale['user-profile-btn']"
            :to="`${$isCreator ? `/${$user.user}` : $links['my-profile']}`"
            @click.native="close"
          />
          <ButtonNav icon="mf_cog" :label="lang['creator-subscribe-config-btn']" :to="$links['my-profile']" @click.native="close" v-if="$isCreator" />
          <ButtonNav icon="mf_money" :label="lang['creator-income-btn']" to="/user/gifts" @click.native="close" v-if="$isCreator" />
          <ButtonNav icon="mf_stats" :label="lang['creator-stats-btn']" to="/?statistics=home" @click.native="close" v-if="$isCreator" />
          <ButtonNav
            icon="mf_check"
            :label="lang['creator-subscribers-btn']"
            to="/?feed=my_subscribers&view=user"
            @click.native="close"
            v-if="$isCreator"
          />
          <ButtonNav icon="mf_fav_solid" :label="$locale['user-favorites-btn']" :to="$favsLink" @click.native="close" v-if="!$isCreator" />
          <ButtonNav
            icon="mf_check"
            :label="$locale['user-subscriptions-btn']"
            to="/?feed=my-subscriptions&view=user"
            @click.native="close"
            v-if="!$isCreator"
          />
        </div>
        <div class="user-menu-card-nav --section" v-if="!$isCreator">
          <ButtonNav
            icon="coins"
            :label="$locale['my_credits']"
            @click="close(() => Go.sleep(200).then(() => menuComponent('CreditsCard', { header: false, class: 'clean menu' })))"
          />
        </div>
        <div class="user-menu-card-nav --section">
          <ButtonNav icon="mf_cog" :label="$locale['user-settings-btn']" :to="$links['my-profile']" @click.native="close" />
          <ButtonNav icon="mf_support" :label="$locale['user-support-btn']" to="/support" @click.native="close" />
          <!-- <ButtonNav icon="mf_moon" :label="$locale['user-theme-dark-btn']" @click.native="darkMode" /> -->
        </div>
        <div class="user-menu-card-nav --section">
          <LanguageSelect />
        </div>
        <div class="user-menu-card-nav --section become-creator" v-if="!$isCreator">
          <UserBecomeCreatorBtn @click.native="close" />
        </div>
        <div class="user-menu-card-nav --section">
          <!-- <ButtonNav icon="mf_accounts" :label="$locale['user-accounts-btn']" to="/" @click.native="close" /> -->
          <ButtonNav icon="mf_logout" :label="$locale['close-sesion-label']" to="/" @click.native="closeSession" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSelect from "../widgets/LanguageSelect.vue";
import HamburgerBtn from "../home/HamburgerBtn.vue";
import ButtonNav from "../buttons/ButtonNav.vue";
import router from "../../router";
import CreatorStatsMenuWidget from "../creator/CreatorStatsMenuWidget.vue";
import UserBecomeCreatorBtn from "../user/UserBecomeCreatorBtn.vue";
export default {
  components: { HamburgerBtn, ButtonNav, LanguageSelect, CreatorStatsMenuWidget, UserBecomeCreatorBtn },
  methods: {
    elements: function(el) {
      const elmns = {
        body: document.querySelector("body"),
        menu: document.querySelector(".user-menu"),
        card: document.querySelector(".user-menu-card"),
        wrap: document.querySelector(".user-menu-bgwrap"),
        custom: document.querySelector(el),
      };
      return elmns[el] || elmns["custom"];
    },
    activate: function(elemn, timeout, active, _class, callback) {
      setTimeout(() => {
        active ? this.elements(elemn)?.classList.add(_class) : this.elements(elemn)?.classList.remove(_class);
        if (callback) callback();
      }, +timeout);
    },
    open: function(cb) {
      this.activate("body", 0, true, "onmenu", () => {});
      this.activate("menu", 0, true, "active", () => {
        this.activate("wrap", 20, true, "active", () => {});
        this.activate("card", 20, true, "active", () => {
          if (Go.is(cb, "Function")) cb();
        });
      });
    },
    close: function(cb) {
      this.activate("body", 0, false, "onmenu", () => {});
      this.activate("card", 0, false, "active", () => {
        this.activate("wrap", 20, false, "active", () => {});
        this.activate("menu", 300, false, "active", () => {
          this.UserMenu(false);
          if (Go.is(cb, "Function")) cb();
        });
      });
    },
    toggleMenu: function(cb) {
      const menu = this.elements("menu");
      if (menu.classList.contains("active")) {
        return this.close(cb);
      }
      return this.open(cb);
    },
    closeSession: function() {
      this.$store.dispatch("removeUser");
      router.push({ path: "/login" });
      this.toggleMenu();
    },
    darkMode: function() {
      const currentMode = this.$payload?.theme;
      if (currentMode === "dark") {
        this.$store.dispatch("payload", { method: "append", theme: "light" });
      } else {
        this.$store.dispatch("payload", { method: "append", theme: "dark" });
      }
    },
    mountedMenu: async function() {
      const menu = this.elements("menu");
      if (!menu) {
        await this.sleep(100);
        return this.mountedMenu();
      }
      return this.toggleMenu();
    },
  },
  mounted: function() {
    this.mountedMenu();
  },
  computed: {
    lang: function() {
      return this.$locale["object"]["nav"];
    },
  },
};
</script>

<style lang="scss">
$avatar_size: 50px;
body.onview .user-menu .nav-button * {
  pointer-events: none;
}
.user-menu {
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &-content {
    height: 100%;
  }
  .user-menu-card {
    position: relative;
    z-index: 2;
    background: $white;
    margin: 0;
    width: 300px;
    overflow: hidden;
    text-align: center;
    float: right;
    box-shadow: 0 2px 10px #00000052;
    overflow: hidden;
    height: 100%;
    overflow-y: auto;
    transition: cubic-bezier(0.39, 0.575, 0.565, 1) 0.3s;
    transform: translateX(100%);
    border-top-left-radius: $mpadding;
    border-bottom-left-radius: $mpadding;
    &.active {
      transform: translateX(0);
    }
    .HambuegerBtn {
      position: absolute;
      top: 0;
      right: 0;
    }
    &-head {
      padding: $mpadding;
      .creator-single-stats {
        padding: $mpadding/2 0 0 0;
      }
      &-content {
        gap: $mpadding;
      }
    }
    &-info {
      text-align: left;
      max-width: calc(100% - #{$mpadding * 2});
      overflow: hidden;
      .name,
      .userName {
        @include ellipsis();
      }
    }
    > *.--section {
      &:not(:last-child) {
        border-bottom: solid 1px $alto;
      }
      &.become-creator {
        padding: $mpadding;
      }
    }
    &-nav {
      .nav-button {
        text-align: left;
        padding: $mpadding/1.2 $mpadding;
        transition: color 0.1s ease, background-color 0.1s ease;
        &:hover {
          background-color: $gray2;
          color: $primary_color;
        }
        .label {
          display: block;
        }

        &.__m1a .icon {
          font-size: 140%;
          color: $primary_color;
        }
        &.__m1a .icon .iconic {
          @include iconic();
        }
      }
    }
  }
  &.active {
    display: block;
  }
  &-bgwrap {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: rgba(0, 0, 0, 0.3);
    transition: 0.3s ease-in-out;
    opacity: 0;
    &.active {
      opacity: 1;
    }
  }
}
</style>
